import React from "react";
import serviceS1 from "@/images/ecommerce.png";
import serviceS2 from "@/images/services.png";
const ServiceEcommerceContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" /> 
      <h2>e-Commerce Development </h2>
      <p>
      eCommerce is an essential part of any modern product-based business. In 2020 the global eCommerce
       market experienced a growth rate of 29% creating 4.3 trillion dollars in revenue. Our team understands 
       the importance of keeping your online store "online". During our eCommerce projects, we put extra
        emphasis on user experience, site security, & site reliability. Our websites are built with scalability 
        in mind, so as your reach increases and your brand grows, your website continues to provide a quality 
        experience to every customer.  

      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6"> 
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Our eCommerce Websites</h3>
          <p>
          How do we create successful eCommerce websites with incredible ROI?
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Engaging & convenient user experience
            </li>
            <li>
              <i className="fa fa-check-square"></i>Designed to optimise sales
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Fully Responsive
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Site security optimisation
            </li>
            <li>
              <i className="fa fa-check-square"></i>Powerful, scalable, & reliable code
            </li>
          </ul>
        </div>
      </div>
      <p>
        We offer easily maintainable eCommerce websites with the option to have a headless CMS. 
        Our sites provide a guided user experience to reduce bounce rate and optimise your conversion
        rates. We also ensure our websites are responsive so every customer has the same great experience
        regardless of their viewing device. Our websites are always scalable and reliable to ensure that 
        your website grows with you and your site always remains available to your customers. 

      </p>
      <p>
      Site security is extremely important for any eCommerce store. A secure site allows your visitors to 
      feel safe visiting your website and paying for products. We use the latest industry-leading techniques 
      and software to keep your website secure. We also offer Cloudflare integration to protect your website 
      from DDOS attacks and keep you online.
      </p>
    </div>
  );
};

export default ServiceEcommerceContent;
